<template>
  <v-row class="my-0">
    <!-- SubOrdenes -->
    <v-col
      v-for="item in suborders"
      :key="`suborder-${order}-${item.pk}`"
      class="pt-2"
      cols="12"
      @click.stop="handleClick(item)"
    >
      <v-card
        v-if="subOrder !== undefined ? item.pk === subOrder : true"
        class="elevation-0 suborderStatus"
      >
        <v-row no-gutters class="py-2">
          <v-col class="pl-2" :cols="2" :sm="3" align-self="center">
            <!-- Imagen del producto -->
            <i-image
              class="product-image"
              icon="false"
              :style="{ width: '5em !important', height: '4em !important' }"
              :value="item.thumbnail"
              :preview="true"
              :readonly="true"
            />
            <p
              v-if="!calendar"
              class="caption font-weight-black secondary--text pt-2 mb-0"
            >
              {{ item.code }}
            </p>
          </v-col>

          <v-col v-if="!calendar" cols="3">
            <div v-if="showSubOrderTitle">
              <p class="body-1 font-weight-black secondary--text mb-1">
                {{ $tc('order', 1) }}: {{ item.projectName }}
              </p>
              <p class="body-2 font-weight-black secondary--text mb-1">
                {{ item.name }}
              </p>
            </div>
            <p v-else class="body-1 font-weight-black secondary--text mb-1">
              {{ item.name }}
            </p>
            <p class="caption font-weight-black secondary--text mb-0">
              {{ item.own_prod }}
            </p>
          </v-col>

          <v-col class="d-flex" :cols="!calendar ? 5 : 8">
            <!-- Contenedor para los progresos de cada subEstado-->
            <div
              v-for="(progress, key) in status[item.pk]"
              :key="`progress-${item.pk}-${key}`"
              class="minisuborder-progress-container"
            >
              <!-- Avance de subEstado -->
              <div>
                <v-progress-circular
                  :color="getProgressColor(progress.color)"
                  :rotate="-90"
                  :size="50"
                  :value="progress.value"
                  :width="5"
                >
                  {{ `${progress.value}%` }}
                </v-progress-circular>
              </div>
              <p
                class="text-center caption pt-1 mb-0"
                :style="{ color: getTextColor(progress.color) }"
              >
                {{ $tc(progress.name, 1) }}
              </p>
            </div>
          </v-col>

          <!-- SubEstado en delivery -->
          <v-col cols="1">
            <span class="body-1 font-weight-black secondary--text">
              {{ $t(item.leader_status['delivery']) }}
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Diálogo de subOrden -->
    <dialprod ref="dialprod" @prod="getSuborder($event)"></dialprod>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import dialprod from './DialogProduct.vue'

export default {
  props: {
    order: Number,
    subOrder: Number,
    calendar: {
      type: Boolean,
      default: false
    },
    miniSubOrder: Object,
    openModal: {
      type: Boolean,
      default: false // Prop para recibir la señal desde el padre
    },
    showSubOrderTitle: {
      type: Boolean,
      default: false
    }
  },
  components: {
    dialprod
  },
  data() {
    return {
      suborders: [],
      status: {},
      loading: false,
      postStatus: {},
      percent: 0
    }
  },
  watch: {
    order: {
      immediate: true,
      handler(val) {
        if (val !== undefined) {
          this.getSuborder()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    /**
     * handleClick
     * Método que maneja el clic en un ítem.
     * Verifica si la cantidad del ítem es mayor que cero y si el usuario tiene
     * permisos para ver el producto de la orden o es admin.
     * Si se cumplen las condiciones, abre el diálogo del producto.
     *
     * @param item - El ítem sobre el cual se ha hecho clic.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    handleClick(item) {
      if (
        item.quantity > 0 &&
        (this.getPermissions(['sales.view_orderproduct']) || this.isAdmin)
      ) {
        this.$refs.dialprod.open(item.pk)
      }
    },

    /**
     * openModalFunction
     *
     * Abrir el modal de la sub-orden cuando el click viene de una notificación
     * del chat.
     *
     * @param id - id de la sub-orden.
     *
     * Ing. Argenis Osorio (argenisosorio10 at gmail)
     */
    openModalFunction(id) {
      // Enviar la señal de abril del modal de la sub-orden.
      this.$refs.dialprod.open(id)
      // Enviar la orden abrir la pestaña chad del modal.
      this.$refs.dialprod.clickChatButton(4)
    },

    /**
     * getSuborder
     * Método asíncrono para obtener las subordenes de una orden específica.
     * Realiza una llamada a la API para recuperar los datos de subordenes,
     * establece el estado de carga y maneja errores durante la recuperación.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getSuborder() {
      this.loading = true
      try {
        const response = await this.$api.sale.product.show({
          pk: this.order,
          opt: { params: { order: true } }
        })

        this.suborders = response.data
        await Promise.all(this.suborders.map(this.fetchStatusForSuborder))
      } catch (error) {
        console.error('Error fetching suborders:', error)
      } finally {
        this.loading = false
      }
    },
    /**
     * fetchStatusForSuborder
     * Método asíncrono que recupera el estado de una suborden específica.
     * Si el templateId es válido y no se ha recuperado previamente, realiza una
     * llamada a la API para obtener los estados.
     * Luego, calcula y emite el estado de la suborden.
     *
     * @param suborder - La suborden para la cual se recupera el estado.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async fetchStatusForSuborder(suborder) {
      const templateId = suborder.template

      if (templateId > 0 && !this.postStatus[templateId]) {
        const stagesResponse = await this.$api.sale.status.list({
          opt: { params: { template: templateId } }
        })
        this.postStatus[templateId] = stagesResponse.data
      }

      const stages = this.postStatus[templateId] || []
      const statuses = stages
        .filter((stage) => stage.name !== 'preprocess')
        .map((stage) => this.calculateStageStatus(stage, suborder))

      this.$set(this.status, suborder.pk, statuses)
      this.$emit('update:miniSubOrder', suborder)
      this.$emit('done', suborder)
    },
    /**
     * calculateStageStatus
     * Calcula el estado de una etapa específica para una suborden.
     * Establece el porcentaje basado en la posición de la suborden dentro de
     * la etapa.
     *
     * @param stage - La etapa para la cual se calcula el estado.
     * @param suborder - La suborden que se está evaluando.
     * @returns Un objeto con información sobre la etapa y su valor.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    calculateStageStatus(stage, suborder) {
      let value = suborder.orderMain === 'finished' ? 100 : 0

      const subStatus = stage.substatusVals.find(
        (item) => item.pk === suborder.status
      )
      const isPreprocessOrDraft =
        suborder.orderStatus.includes('preprocess') ||
        suborder.orderMain === 'draft'

      this.percent = isPreprocessOrDraft ? 0 : 100
      if (subStatus) {
        this.root = subStatus.root
        this.percent = Math.round(
          ((subStatus.position - 2) * 100) /
            Math.max(stage.substatusVals.length - 2, 1)
        )
      }

      if (stage.pk > this.root) {
        this.percent = 0
      }

      const isPositioned = this.calculateStatusValue(stage, suborder)
      if (isPositioned) {
        value = Math.min(Math.max(value, 0), 100)
      }

      return {
        pk: stage.pk,
        name: stage.name,
        color: stage.color,
        value: Math.max(0, Math.min(this.percent, 100))
      }
    },
    /**
     * calculateStatusValue
     * Determina si una etapa está posicionada para una suborden dada.
     * Retorna verdadero si la suborden está en la etapa o si no tiene estado.
     *
     * @param stage - La etapa a evaluar.
     * @param suborder - La suborden que se está evaluando.
     * @returns Verdadero si la suborden está en la etapa, falso en caso contrario.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    calculateStatusValue(stage, suborder) {
      if (suborder.mainStatus === 'finished' || suborder.status === null) {
        return false
      }
      if (stage.pk === suborder.status || stage.substatusVals.length === 0) {
        return true
      }
      return stage.substatusVals.some(
        (substatus) => substatus.pk === suborder.status
      )
    },
    /**
     * getProgressColor
     * Devuelve el color de progreso basado en el color proporcionado.
     * Si el color es blanco, devuelve negro.
     *
     * @param color - El color a evaluar.
     * @returns El color adecuado para el progreso.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getProgressColor(color) {
      return color === '#FFFFFF' || color === '#FFF' ? 'black' : color
    },
    /**
     * getTextColor
     * Devuelve el color del texto basado en el color proporcionado.
     * Si el color es blanco, devuelve negro.
     *
     * @param color - El color a evaluar.
     * @returns El color adecuado para el texto.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getTextColor(color) {
      return color === '#FFFFFF' || color === '#FFF' ? 'black' : color
    }
  },
  mounted() {
    // Señal para abrir el modal de la suborden desde una notificaióin del chat.
    if (this.openModal) {
      this.openModalFunction(this.subOrder)
    }
  }
}
</script>
<style lang="sass">
.minisuborder-progress-container
  display: flex
  width: 100%
  padding-left: 5px
  padding-right: 5px
  align-items: center
  flex-direction: column
  justify-content: center
  .v-progress-circular__info
    font-size: 13px
</style>
